import axios from './baseService/axiosInstance.service';

const URL = process.env.REACT_APP_API_URL;

export const adminSignIn = async (body: any) => {
  const data = await axios.post(`${URL}/admin-signin`, body);
  return data;
};

export const applicationList = async (schoolId: any, incompleteForms: boolean) => {
  // const data = await axios.get(`${URL}/applicant?schoolId=${schoolId}`);
  const data = await axios.get(`${URL}/applicant?incompleteForm=${incompleteForms}`);
  return data;
};

export const applicationListBySchoolId = async (id: any) => {
  const data = await axios.get(`${URL}/school-applicant/${id}`);
  return data;
};

export const applicationStatus = async (id: any, body: any, schoolId: any) => {
  const data = await axios.put(`${URL}/applicant-status/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const updateMultipleApplicationStatus = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/multiple-applicant-status/?schoolId=${schoolId}`, body);
  return data;
};

export const applicationById = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/applicant/${id}?schoolId=${schoolId}`);
  return data;
};

export const getStatusEnum = async () => {
  const data = await axios.get(`${URL}/enum?groupName=applicationStatus`);
  return data;
};

export const addSubAdmin = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/sub-admin?schoolId=${schoolId}`, body);
  return data;
};

export const getSubAdmin = async (schoolId: any) => {
  const data = await axios.get(`${URL}/sub-admin?schoolId=${schoolId}`);
  return data;
};

export const deleteSubAdmin = async (id: any, schoolId: any) => {
  const data = await axios.delete(`${URL}/sub-admin/${id}?schoolId=${schoolId}`);
  return data;
};

export const getSubAdminById = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/sub-admin/${id}?schoolId=${schoolId}`);
  return data;
};

export const editSubAdminById = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/sub-admin/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const favouriteApplicant = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/applicant-favourite/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const getFavouriteApplicantList = async (schoolId: any) => {
  const data = await axios.get(`${URL}/favourite-applicant?schoolId=${schoolId}`);
  return data;
};

export const applicantPayment = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/applicant-payment/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const getAdmin = async () => {
  const data = await axios.get(`${URL}/admin`);
  return data;
};

export const updateAdmin = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/admin/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const getAdminById = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/admin/${id}?schoolId=${schoolId}`);
  return data;
};

export const addTask = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/admin-task?schoolId=${schoolId}`, body);
  return data;
};
export const postReason = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/reason?schoolId=${schoolId}`, body);
  return data;
};
export const EditReason = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/reason/${id}?schoolId=${schoolId}`, body);
  return data;
};
export const getlist = async (schoolId: any, status: any) => {
  const data = await axios.get(`${URL}/reason/${status}?schoolId=${schoolId}`);
  return data;
};
export const deleteReasons = async (id: any, schoolId: any) => {
  const data = await axios.delete(`${URL}/reason/${id}?schoolId=${schoolId}`);
  return data;
};

export const editTask = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/admin-task/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const getTasks = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/admin-task/${id}?schoolId=${schoolId}`);
  return data;
};

export const deleteTask = async (id: any, schoolId: any) => {
  const data = await axios.delete(`${URL}/admin-task/${id}?schoolId=${schoolId}`);
  return data;
};

export const activeDeactiveSubadmin = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/sub-admin-active-deactive/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const getReasonByStatus = async (status: any, schoolId: any) => {
  const data = await axios.get(`${URL}/reason/${status}?schoolId=${schoolId}`);
  return data;
};

export const publishStatus = async (body: any, schoolId: any) => {
  const data = await axios.put(
    `${URL}/publish-academic-year-grade-status?schoolId=${schoolId}`,
    body
  );
  return data;
};
