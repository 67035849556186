import axios from './baseService/axiosInstance.service';
const URL = process.env.REACT_APP_API_URL;

export const getSchools = async () => {
  const data = await axios.get(`${URL}/school`);
  return data;
};

export const getSchoolById = async (id: any) => {
  const data = await axios.get(`${URL}/school/${id}`);
  return data;
};

export const addSchool = async (value: any) => {
  const data = await axios.post(`${URL}/school`, value);
  return data;
};

export const editSchool = async (id: any, value: any) => {
  const data = await axios.put(`${URL}/school/${id}`, value);
  return data;
};

export const deleteSchool = async (id: any) => {
  const data = await axios.delete(`${URL}/school/${id}`);
  return data;
};

export const activateDeactivateSchool = async (id: any, value: any) => {
  const data = await axios.put(`${URL}/school-active-deactive/${id}`, value);
  return data;
};

export const schoolSubscription = async (id: any, body: any) => {
  const data = await axios.put(`${URL}/school-subscribe-unsubscribe/${id}`, body);
  return data;
};

//verify school endpoint
export const verifyEndpoint = async (value: any) => {
  const data = await axios.post(`${URL}/verify-school-endpoint`, value);
  return data;
};

export const verifyEditEndpoint = async (schoolId: any, value: any) => {
  const data = await axios.post(`${URL}/verify-school-endpoint?schoolId=${schoolId}`, value);
  return data;
};

export const verifySchool = async (schoolName: any) => {
  const data = await axios.get(`${URL}/verify-school?school=${schoolName}`);
  return data;
};

export const verifyApplicationIdPrefix = async (value: any) => {
  const data = await axios.post(`${URL}/verify-school-applicationid-prefix`, value);
  return data;
};

export const addGrade = async (value: any, schoolId: any) => {
  const data = await axios.post(`${URL}/grade?schoolId=${schoolId}`, value);
  return data;
};

export const getGrade = async (schoolId: any) => {
  const data = await axios.get(`${URL}/grade?schoolId=${schoolId}`);
  return data;
};

export const getActiveGrade = async (schoolId: any) => {
  const data = await axios.get(`${URL}/grade-active?schoolId=${schoolId}`);
  return data;
};

export const updateGrade = async (id: any, body: any) => {
  const data = await axios.put(`${URL}/grade-active-deactive/${id}`, body);
  return data;
};

export const deleteGrade = async (id: any, schoolId?: any) => {
  const data = await axios.delete(`${URL}/grade/${id}/?schoolId=${schoolId}`);
  return data;
};

export const addAcademicYear = async (value: any, schoolId: any) => {
  const data = await axios.post(`${URL}/academic-year?schoolId=${schoolId}`, value);
  return data;
};

export const getAcademicYear = async (schoolId: any) => {
  const data = await axios.get(`${URL}/academic-year?schoolId=${schoolId}`);
  return data;
};

export const getActiveAcademicYear = async (schoolId: any) => {
  const data = await axios.get(`${URL}/academic-year-active?schoolId=${schoolId}`);
  return data;
};

export const updateAcademicYear = async (id: any, body: any) => {
  const data: any = await axios.put(`${URL}/academic-year-active-deactive/${id}`, body);
  return data;
};

export const deleteAcademicYear = async (id: any, schoolId?: any) => {
  const data = await axios.delete(`${URL}/academic-year/${id}?schoolId=${schoolId}`);
  return data;
};

export const addFeeStructure = async (value: any, schoolId: any) => {
  const data = await axios.post(`${URL}/fee-academic-year-grade?schoolId=${schoolId}`, value);
  return data;
};

export const getFeeStructure = async (schoolId: any) => {
  const data = await axios.get(`${URL}/fee-academic-year-grade?schoolId=${schoolId}`);
  return data;
};

export const deleteFeeStructure = async (id: any) => {
  const data = await axios.delete(`${URL}/fee-academic-year-grade/${id}`);
  return data;
};

export const updateFeeStructure = async (id: any, schoolId: any, body: any) => {
  const data = await axios.put(
    `${URL}/update-fee-of-academic-year-grade/${id}?schoolId=${schoolId}`,
    body
  );
  return data;
};

export const addEmailMaster = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/email-master?schoolId=${schoolId}`, body);
  return data;
};

export const getEmailMaster = async (schoolId: any) => {
  const data = await axios.get(`${URL}/email-master?schoolId=${schoolId}`);
  return data;
};

export const deleteEmailMaster = async (id: any) => {
  const data = await axios.delete(`${URL}/email-master/${id}`);
  return data;
};

export const updateEmailMaster = async (id: any, schoolId: any, body: any) => {
  const data = await axios.put(`${URL}/email-master/${id}?schoolId=${schoolId}`, body);
  return data;
};

export const activateEmailMaster = async (id: any, schoolId: any) => {
  const data = await axios.get(`${URL}/email-master/activate-email/${id}?schoolId=${schoolId}`);
  return data;
};

export const addNotificationTemplate = async (body: any, schoolId: any) => {
  const data = await axios.post(`${URL}/notification-template?schoolId=${schoolId}`, body);
  return data;
};
export const updateNotificationTemplate = async (body: any, id: any, schoolId: any) => {
  const data = await axios.put(`${URL}/notification-template/${id}?schoolId=${schoolId}`, body);
  return data;
};
export const getNotificationTemplate = async (schoolId: any) => {
  const data = await axios.get(`${URL}/notification-template?schoolId=${schoolId}`);
  return data;
};
export const getFeeByAcademicYearAndGrade = async (
  schoolId: any,
  academicYearId: number,
  gradeId: number
) => {
  const data = await axios.get(
    `${URL}/school-fee-academic-year-grade?academicYearId=${academicYearId}&gradeId=${gradeId}&schoolId=${schoolId}`
  );
  return data;
};
