import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, Avatar, Drawer, Tooltip, Typography, CardActionArea } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { MHidden } from '../../components/@material-extend';
import {
  adminSidebarConfig,
  candidateSidebarConfig,
  SidebarItems,
  subAdminSidebarConfig,
  superAdminSidebarConfig
} from './SidebarConfig';
import { DocIllustration } from 'assets';
import { useTranslation } from 'react-i18next';
import DrawerComponent, { DRAWER_NAV_WIDTH } from './drawer-component/DrawerComponent';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 100;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[500_12]
// }));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu" style={{ display: 'none' }}>
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          // border: 'solid 1px currentColor',
          ...(collapseClick &&
            {
              // borderWidth: 2
            })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  // const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isAdmin, setIsAdmin] = useState<any>(false);
  var schoolData: any = localStorage.getItem('schoolData');
  schoolData = JSON.parse(schoolData);

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    const user = localStorage.getItem('role');
    if (user === 'admin') {
      setIsAdmin('admin');
    } else if (user === 'superadmin') {
      setIsAdmin('superadmin');
    } else if (user === 'subadmin') {
      setIsAdmin('subadmin');
    } else if (user === 'candidate') {
      setIsAdmin('candidate');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  //#region
  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Stack
        className="CUSTOM_WIDTH_STACK"
        spacing={3}
        sx={{
          // px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          }),
          width: DRAWER_NAV_WIDTH,
          ml: 4,
          borderRight: 'none !important'
        }}
      >
        {isCollapse ? (
          <Avatar
            alt="My Avatar"
            src="/static/mock-images/avatars/avatar_default.jpg"
            sx={{ mx: 'auto', mb: 2 }}
          />
        ) : (
          ''
        )}
      </Stack>

      <>
        {isAdmin === 'admin' ? (
          <>
            {/* <NavSection navConfig={adminSidebarConfig} isShow={!isCollapse} /> */}

            <DrawerComponent
              menus={adminSidebarConfig.reduce((p: SidebarItems[], c) => [...p, ...c.items], [])}
              currentMenu={[]}
            />

            {/* <Box sx={{ flexGrow: 1 }} /> */}
          </>
        ) : isAdmin === 'superadmin' ? (
          // <NavSection navConfig={superAdminSidebarConfig} isShow={!isCollapse} />
          <DrawerComponent
            menus={superAdminSidebarConfig.reduce((p: SidebarItems[], c) => [...p, ...c.items], [])}
            currentMenu={[]}
          />
        ) : isAdmin === 'subadmin' ? (
          <>
            {/* <NavSection navConfig={subAdminSidebarConfig} isShow={!isCollapse} /> */}
            <DrawerComponent
              menus={subAdminSidebarConfig.reduce((p: SidebarItems[], c) => [...p, ...c.items], [])}
              currentMenu={[]}
            />
            {/* <Box sx={{ flexGrow: 1 }} /> */}
          </>
        ) : isAdmin === 'candidate' ? (
          <>
            {/* <NavSection navConfig={candidateSidebarConfig} isShow={!isCollapse} /> */}
            <DrawerComponent
              menus={candidateSidebarConfig.reduce(
                (p: SidebarItems[], c) => [...p, ...c.items],
                []
              )}
              currentMenu={[]}
            />
            {/* <Box sx={{ flexGrow: 1 }} /> */}
          </>
        ) : (
          <></>
        )}
      </>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        }),
        mt: 10
      }}
    >
      {/* <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden> */}

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              }),
              mt: 10
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

// {/* <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}>
//   <div>
//     <Typography fontSize="22px" gutterBottom variant="subtitle1">
//       {schoolData?.name ? schoolData.name : t('N/A')}
//     </Typography>
//     <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//       {schoolData?.address ? schoolData.address : t('N/A')}
//       <br /> {schoolData?.city ? schoolData.city : t('N/A')}
//       <br /> {schoolData?.country ? schoolData.country : t('N/A')}
//     </Typography>
//   </div>
// </Stack>; */}

// {/* <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}>
//   <div>
//     <Typography fontSize="22px" gutterBottom variant="subtitle1">
//       {schoolData?.name ? schoolData.name : t('N/A')}
//     </Typography>
//     <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//       {schoolData?.address ? schoolData.address : t('N/A')}
//       <br /> {schoolData?.city ? schoolData.city : t('N/A')}
//       <br /> {schoolData?.country ? schoolData.country : t('N/A')}
//     </Typography>
//   </div>
// </Stack>; */}

// <Stack
//   spacing={3}
//   alignItems="center"
//   sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
// >
//   <div>
//     <Typography fontSize="22px" gutterBottom variant="subtitle1">
//       {schoolData?.name ? schoolData.name : 'N/A'}
//     </Typography>
//     <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//       {schoolData?.streetAddress ? schoolData.streetAddress : 'N/A'}
//       <br /> {schoolData?.city ? schoolData.city : 'N/A'}
//       <br /> {schoolData?.country ? schoolData.country : 'N/A'}
//     </Typography>
//   </div>
// </Stack>
