// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SUPER_ADMIN = '/super-admin';
const ROOTS_ADMIN = '/admin';
const ROOTS_CANDIDATE = '/applicant';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    admissionForm: path(ROOTS_DASHBOARD, '/admission-form'),
    candidateLogin: path(ROOTS_DASHBOARD, '/applicant-login'),
    pageTwo: path(ROOTS_DASHBOARD, '/two'),
    pageThree: path(ROOTS_DASHBOARD, '/three')
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/app'),
    pageFour: path(ROOTS_DASHBOARD, '/app/four'),
    pageFive: path(ROOTS_DASHBOARD, '/app/five'),
    pageSix: path(ROOTS_DASHBOARD, '/app/six')
  },
  admin: {
    adminConfigForm: path(ROOTS_DASHBOARD, '/admin-form'),
    applicationList: path(ROOTS_DASHBOARD, '/application-list'),
    applicationView: path(ROOTS_DASHBOARD, '/application-view')
  },
  verfication: {
    verification: path('/', 'verification')
  },
  password: {
    password: path('/', 'forgot-password')
  },
  changePassword: {
    changePassword: path('/', 'change-password')
  }
};

export const PATH_CANDIDATE = {
  root: ROOTS_CANDIDATE,
  candidate: {
    admissionForm: path(ROOTS_CANDIDATE, '/admission-form'),
    candidateDashboard: path(ROOTS_CANDIDATE, '/dashboard'),
    applicationView: path(ROOTS_CANDIDATE, '/application-view'),
    candidateChat: path(ROOTS_CANDIDATE, '/candidate-chat'),
    setup: path(ROOTS_CANDIDATE, '/setup'),
    candidateLogin: path(ROOTS_DASHBOARD, '/applicant-login')
  }
};

export const PATH_SUPER_ADMIN = {
  root: ROOTS_SUPER_ADMIN,
  superAdmin: {
    addSchool: path(ROOTS_SUPER_ADMIN, '/add-school'),
    schoolList: path(ROOTS_SUPER_ADMIN, '/school-list'),
    adminList: path(ROOTS_SUPER_ADMIN, '/admin-list'),
    dashboard: path(ROOTS_SUPER_ADMIN, '/dashboard'),
    editAdmin: path(ROOTS_SUPER_ADMIN, '/edit-admin')
  }
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  admin: {
    adminConfigForm: path(ROOTS_ADMIN, '/admin-form'),
    admissionFormPage: path(ROOTS_ADMIN, '/admission-form'),
    applicationList: path(ROOTS_ADMIN, '/application-list'),
    addmissionForm: path(ROOTS_ADMIN, '/admission-form'),
    applicationView: path(ROOTS_ADMIN, '/application-view'),
    addSubAdmin: path(ROOTS_ADMIN, '/add-sub-admin'),
    schoolInformation: path(ROOTS_ADMIN, '/school-information'),
    subAdminList: path(ROOTS_ADMIN, '/sub-admin-list'),
    editSubAdmin: path(ROOTS_ADMIN, '/edit-sub-admin'),
    favourites: path(ROOTS_ADMIN, '/favourites'),
    chat: path(ROOTS_ADMIN, '/chat'),
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    schoolSetup: path(ROOTS_ADMIN, '/school-setup'),
    feeStructure: path(ROOTS_ADMIN, '/fee-structure'),
    notificationTemplate: path(ROOTS_ADMIN, '/notification-template'),
    round: {
      root: path(ROOTS_ADMIN, '/round'),
      list: path(ROOTS_ADMIN, '/round/list'),
      create: path(ROOTS_ADMIN, '/round/create'),
      edit: path(ROOTS_ADMIN, '/round/edit/:id'),
      view: path(ROOTS_ADMIN, '/round/view/:id'),
      examList: path(ROOTS_ADMIN, '/round/exam/list'),
      // evaluationList: path(ROOTS_ADMIN, '/round/evaluation/list'),
      evaluation: {
        root: path(ROOTS_ADMIN, '/round/evaluation'),
        list: path(ROOTS_ADMIN, '/round/evaluation/list')
        // evaluate: path(ROOTS_ADMIN, '/round/evaluation/'),
        // create: path(ROOTS_ADMIN, '/round/evaluation/create'),
        // edit: path(ROOTS_ADMIN, '/round/evaluation/edit'),
        // view: path(ROOTS_ADMIN, '/round/evaluation/view'),
      }
    },
    academicYearGrade: {
      root: path(ROOTS_ADMIN, '/academic-year-grade'),
      list: path(ROOTS_ADMIN, '/academic-year-grade/list'),
      create: path(ROOTS_ADMIN, '/academic-year-grade/create'),
      edit: path(ROOTS_ADMIN, '/academic-year-grade/edit/:id'),
      view: path(ROOTS_ADMIN, '/academic-year-grade/view/:id')
    },
    academicYearGradeRound: {
      root: path(ROOTS_ADMIN, '/academic-year-grade-round'),
      list: path(ROOTS_ADMIN, '/academic-year-grade-round/list'),
      create: path(ROOTS_ADMIN, '/academic-year-grade-round/create'),
      edit: path(ROOTS_ADMIN, '/academic-year-grade-round/edit/:id'),
      view: path(ROOTS_ADMIN, '/academic-year-grade-round/view/:id')
    },
    // template editor
    templateEditor: {
      root: path(ROOTS_ADMIN, '/template-editor'),
      viewTemplateEditor: path(ROOTS_ADMIN, '/template-editor/list'),
      createTemplateEditor: path(ROOTS_ADMIN, '/template-editor/create'),
      editTemplateEditor: path(ROOTS_ADMIN, '/template-editor/edit')
    },
    transaction: {
      root: path(ROOTS_ADMIN, '/transaction'),
      list: path(ROOTS_ADMIN, '/transaction/list')
    },
    invoiceTemplate: {
      root: path(ROOTS_ADMIN, '/invoice-template')
    }
  }
};
