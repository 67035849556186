// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import './styles.css';
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { verifySchool } from 'Services/school.service';
import i18next from './locales/i18n';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SettingsContext } from 'contexts/SettingsContext';
import jsCookie from 'js-cookie';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSchool } from 'actions';
// ----------------------------------------------------------------------

export default function App() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { schoolName } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const schoolId: any = localStorage.getItem('schoolId');
  // const defaultSchoolLanguage: any = localStorage.getItem('schoolDefaultLanguage');
  const schoolObj: any = useSelector((state: any) => state.school);
  const defaultSchoolLanguage: any = schoolObj?.defaultLanguage?.langCode;
  const role: any = localStorage.getItem('role');
  const i18NextLanguage = jsCookie.get('i18next');

  const [isReady, setIsReady] = useState(false);

  const settingContext = useContext(SettingsContext);

  const getSchoolDetails = async (schoolName: any) => {
    try {
      const schoolDetailsResponse = await verifySchool(schoolName);
      if (schoolDetailsResponse.data && schoolDetailsResponse.data.status) {
        //#region
        localStorage.setItem('schoolName', `/school/${schoolName}`);
        localStorage.setItem('schoolId', schoolDetailsResponse.data.data.user.id);
        dispatch(setSchool(schoolDetailsResponse.data.data.user));
        const schoolData = JSON.stringify(schoolDetailsResponse.data.data.user);
        localStorage.setItem('schoolData', schoolData);
        localStorage.setItem(
          'schoolDefaultLanguage',
          schoolDetailsResponse.data.data.user.defaultLanguage.langCode
        );
        localStorage.setItem(
          'schoolLanguages',
          JSON.stringify(schoolDetailsResponse.data.data.user.languageMasters)
        );

        // i18
        const baseUrl = process.env.REACT_APP_API_URL;
        const requestSchoolId = schoolDetailsResponse.data.data.user.id;
        const requestSchoolDefaultLanguage =
          schoolDetailsResponse.data.data.user.defaultLanguage.langCode;

        i18next.init({
          supportedLngs: ['ar', 'en', 'es', 'fr'],

          // default language set
          // lng: requestSchoolDefaultLanguage,

          // fallbackLng: requestSchoolDefaultLanguage,

          keySeparator: false,

          detection: {
            order: ['cookie', 'navigator', 'htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie']
          },

          backend: {
            loadPath: `${baseUrl}/translation/{{lng}}?schoolId=${requestSchoolId}`,
            allowMultiLoading: true
          }
        });
        //#endregion
        if (state && (state as any).from) {
          navigate(`/school/${schoolName}/${(state as any).from}`);
        } else {
          navigate(`/school/${schoolName}/verification`);
        }
      } else {
        navigate('*');
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        )
      });
      navigate('*');
    }
  };

  useEffect(() => {
    if (i18NextLanguage) {
      settingContext.onLanguageDirectionChange(i18NextLanguage);
    }

    if (schoolName) {
      getSchoolDetails(schoolName);
    } else {
      if (schoolId) {
        i18next.init({
          supportedLngs: ['ar', 'en', 'es', 'fr'],

          // // default language set
          // lng: defaultSchoolLanguage,

          // fallbackLng: defaultSchoolLanguage,

          keySeparator: false,

          detection: {
            order: ['cookie', 'navigator', 'htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie']
          },

          backend: {
            loadPath: `${baseUrl}/translation/{{lng}}?schoolId=${schoolId}`,
            allowMultiLoading: true
          }
        });

        if (!isReady) {
          setIsReady(true);
        }
      } else {
        i18next.init({
          supportedLngs: ['ar', 'en', 'es', 'fr'],

          // // default language set
          // lng: 'en',

          // fallbackLng: 'en',

          keySeparator: false,

          detection: {
            order: ['cookie', 'navigator', 'htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie']
          },

          backend: {
            loadPath: `${baseUrl}/translation/{{lng}}?schoolId=${1}`,
            allowMultiLoading: true
          }
        });
        if (!isReady) {
          setIsReady(true);
        }
      }
    }
  }, [schoolId, defaultSchoolLanguage, role, baseUrl, schoolName, i18NextLanguage]);

  return isReady ? (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <GlobalStyles />
          <ProgressBarStyle />
          <Settings />
          <ScrollToTop />
          <Router />
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  ) : (
    <></>
  );
}
