const schoolReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case 'SET_SCHOOL':
      return action.payload;

    case 'CLEAR_SCHOOL':
      return state;

    default:
      return state;
  }
};

export default schoolReducer;
