const unreadMessageCountReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case 'SET_UNREAD_MESSAGE_COUNT':
      return action.payload;

    case 'CLEAR_UNREAD_MESSAGE_COUNT':
      return state;

    default:
      return state;
  }
};
export default unreadMessageCountReducer;
