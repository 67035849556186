import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { PATH_CANDIDATE } from '../../routes/paths';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  IconButton
} from '@mui/material';
import mockData from '../../utils/mock-data';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import {
  getAdminUnreadMessageCount,
  getCandidateUnreadMessageCount,
  getCandidateUnreadNotificationCount
} from 'Services/conversation.service';

import jsCookie from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationCount, setUnreadMessageCount } from 'actions';
import { id } from 'date-fns/locale';
import notificationCount from 'reducers/notificationCount';
import { postNotificationRead } from 'Services/candidate.service';
import user from 'redux/slices/user';

// ----------------------------------------------------------------------

const TITLES = [
  'Your order is placed',
  'Sylvan King',
  'You have new message',
  'You have new mail',
  'Delivery processing'
];

const DESCRIPTIONS = [
  'waiting for shipping',
  'answered to your comment on the Minimal',
  '5 unread messages',
  'sent from Guido Padberg',
  'Your order is being shipped'
];

const TYPES = ['order_placed', 'friend_interactive', 'chat_message', 'mail', 'order_shipped'];

const AVATARS = [null, mockData.image.avatar(2), null, null, null];

const UNREADS = [true, true, false, false, false];

const MOCK_NOTIFICATIONS = [...Array(5)].map((_, index) => ({
  id: mockData.id(index),
  title: TITLES[index],
  description: DESCRIPTIONS[index],
  avatar: AVATARS[index],
  type: TYPES[index],
  createdAt: mockData.time(index),
  isUnRead: UNREADS[index]
}));

// ----------------------------------------------------------------------

type TNotificationPopover = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
};

function renderContent(notification: TNotificationPopover) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title
  };
}

function NotificationItem({ notification }: { notification: TNotificationPopover }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  let reduxNotiCount;
  let notification: any;
  let count: any;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(MOCK_NOTIFICATIONS);
  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const dispatch = useDispatch();
  const i18NextLanguage = jsCookie.get('i18next');

  const getUnreadMessageCount = async () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if (user.role === 'admin' || user.role === 'subadmin') {
      try {
        const candidateUnreadMsgCount_Admin_Response: any = await getAdminUnreadMessageCount(
          localStorage.getItem('schoolId'),
          localStorage.getItem('schoolId'),
          'candidate'
        );
        if (
          candidateUnreadMsgCount_Admin_Response.data &&
          candidateUnreadMsgCount_Admin_Response.data.statusText === 'success'
        ) {
          localStorage.setItem(
            'unreadMsgCount',
            String(candidateUnreadMsgCount_Admin_Response.data.data.unreadMsgCount)
          );
          dispatch(
            setUnreadMessageCount(candidateUnreadMsgCount_Admin_Response.data.data.unreadMsgCount)
          );
        }
      } catch (error) {}
    }
    if (user.role === 'candidate') {
      try {
        let user: any = localStorage.getItem('user');
        user = JSON.parse(user);
        let id: any = user.id;
        const candidateUnreadNotificationCount_Admin_Response: any =
          await getCandidateUnreadNotificationCount(id);
        if (
          candidateUnreadNotificationCount_Admin_Response.data &&
          candidateUnreadNotificationCount_Admin_Response.data.statusText === 'success'
        ) {
          localStorage.setItem(
            'unreadnotifiCount',
            String(candidateUnreadNotificationCount_Admin_Response.data.data.unreadMsgCount)
          );

          dispatch(
            setNotificationCount(
              candidateUnreadNotificationCount_Admin_Response.data.data.notifications
            )
          );
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getUnreadMessageCount();
  }, []);

  const totalUnRead = localStorage.getItem('unreadMsgCount') || 0;
  let reduxMsgCount = useSelector((state: any) => state.unreadMessageCount);
  if (reduxMsgCount && Object.keys(reduxMsgCount).length) {
    reduxMsgCount = Object.keys(reduxMsgCount).length;
  } else {
    reduxMsgCount = 0;
  }
  const totalUnReadnotifi = localStorage.getItem('unreadnotifiCount') || 0;
  reduxNotiCount = useSelector((state: any) => state.notificationCount);
  for (let i = 0; i < reduxNotiCount.length; i++) {
    if (reduxNotiCount[i].read === false) {
      count = reduxMsgCount + 1;
      notification = reduxNotiCount[i];
      break;
    }
  }
  const onSubmit = async () => {
    try {
      let user: any = localStorage.getItem('user');
      user = JSON.parse(user);
      const temp = {
        applicationId: user.id
      };
      const NotificationRead: any = await postNotificationRead(temp);
      if (NotificationRead.data.statusText === 'success') {
        try {
          let user: any = localStorage.getItem('user');
          user = JSON.parse(user);
          let id: any = user.id;
          const candidateUnreadNotificationCount_Admin_Response: any =
            await getCandidateUnreadNotificationCount(id);
          if (
            candidateUnreadNotificationCount_Admin_Response.data &&
            candidateUnreadNotificationCount_Admin_Response.data.statusText === 'success'
          ) {
            localStorage.setItem(
              'unreadnotifiCount',
              String(candidateUnreadNotificationCount_Admin_Response.data.data.unreadMsgCount)
            );

            dispatch(
              setNotificationCount(
                candidateUnreadNotificationCount_Admin_Response.data.data.notifications
              )
            );
          }
        } catch (error) {}
      }
    } catch (error) {}
  };

  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false
      }))
    );
  };

  const onViewAllButtonClicked = () => {
    if (localStorage.getItem('role') === 'candidate') {
      navigate(`${PATH_CANDIDATE.candidate.candidateChat}`);
    } else {
      navigate('/admin/chat');
    }
  };
  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={count} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {/* Notifications */}
              {i18NextLanguage === 'ar'
                ? 'إشعارات'
                : i18NextLanguage === 'en'
                ? 'Notifications'
                : i18NextLanguage === 'fr'
                ? 'Avis'
                : i18NextLanguage === 'es'
                ? 'Notificaciones'
                : 'Notifications'}
            </Typography>
            {totalUnReadnotifi == 0 && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {notification
                  ? notification.description
                  : i18NextLanguage === 'ar'
                  ? 'لا يوجد إشعارات'
                  : i18NextLanguage === 'en'
                  ? 'No Notifications'
                  : i18NextLanguage === 'fr'
                  ? 'Aucune notification'
                  : i18NextLanguage === 'es'
                  ? 'No Notificaciones'
                  : 'No Notifications'}
              </Typography>
            )}
            {count > 0 && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {/* {totalUnReadnotifi} Unread notifications */}
                {i18NextLanguage === 'ar'
                  ? `تم تغيير حالة طلبك`
                  : i18NextLanguage === 'en'
                  ? `Your application status has been changed`
                  : i18NextLanguage === 'fr'
                  ? `Le statut de votre demande a été modifié`
                  : i18NextLanguage === 'es'
                  ? `Se ha cambiado el estado de su solicitud`
                  : `Your application status has been changed`}
              </Typography>
            )}
            <IconButton onClick={onSubmit} sx={{ ml: 36, mt: -8 }}>
              <DoneAllIcon color="primary" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">
                {/* msg */}
                {i18NextLanguage === 'ar'
                  ? 'رسائل'
                  : i18NextLanguage === 'en'
                  ? 'Messages'
                  : i18NextLanguage === 'fr'
                  ? 'Messages'
                  : i18NextLanguage === 'es'
                  ? 'Mensajes'
                  : 'Messages'}
              </Typography>
              {totalUnRead == 0 && (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {/* No unread messages */}
                  {i18NextLanguage === 'ar'
                    ? 'لا توجد رسائل غير مقروءة'
                    : i18NextLanguage === 'en'
                    ? 'No unread messages'
                    : i18NextLanguage === 'fr'
                    ? 'Aucun message non lu'
                    : i18NextLanguage === 'es'
                    ? 'Sin mensajes sin leer'
                    : 'No unread messages'}
                </Typography>
              )}
              {totalUnRead > 0 && (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {i18NextLanguage === 'ar'
                    ? ` ${totalUnRead} رسائل غير مقروءة`
                    : i18NextLanguage === 'en'
                    ? `${totalUnRead} unread messages`
                    : i18NextLanguage === 'fr'
                    ? `${totalUnRead} Messages non lus`
                    : i18NextLanguage === 'es'
                    ? `${totalUnRead} Mensajes no leídos`
                    : `${totalUnRead} unread messages`}
                </Typography>
              )}
            </Box>
          </Box>

          {/* {reduxMsgCount > 0 && (
            <Tooltip title="Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}*/}
          {/*
           */}
        </Box>

        {/* <Divider /> */}

        {/* <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar> */}

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            onClick={onViewAllButtonClicked}
            fullWidth
            disableRipple
            // component={RouterLink}
            // to="#"
          >
            {/* View All */}
            {i18NextLanguage === 'ar'
              ? 'مشاهدة الكل'
              : i18NextLanguage === 'en'
              ? 'View All'
              : i18NextLanguage === 'fr'
              ? 'Voir tout'
              : i18NextLanguage === 'es'
              ? 'Ver todo'
              : 'View All'}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
