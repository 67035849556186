import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
  Button
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Iconify from 'components/Iconify';
// import { DRAWER_NAV_WIDTH } from 'config';
// import { applicationCurrentNav } from 'redux/slices/userAccess';
import { dispatch, useDispatch, useSelector } from 'redux/store';
import _ from 'lodash';
// import { bgBlur } from 'utils/cssStyles';
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';
import { bgBlur } from 'utils/cssStyles';
import { NavItemType, SidebarConfigType, SidebarItems } from '../SidebarConfig';

export const DRAWER_NAV_WIDTH = 120;

type Props = {
  menus: SidebarItems[];
  currentMenu: any;
};

const DrawerComponent: React.FC<Props> = ({ menus, currentMenu }) => {
  if (menus && menus?.length && menus?.length <= 1) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.light;
  const navigate = useNavigate();
  // const { translate: t } = useLocales();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navRef = useRef(null);
  const loacation = useLocation();

  //#region
  const [currentActiveNav, setCurrentActiveNav] = useState<SidebarItems | null>(
    menus.find((m) => {
      return m.path === location.pathname;
    }) || null
  );
  const [activeMenuChild, setActiveMenuChild] = useState<SidebarItems | null>(null);

  // const handleOpen = (index: number | undefined) => {
  //   if (index) {
  //     setActiveMenuChild(menus[index]);
  //   }
  //   setOpen(true);
  // };
  const { themeDirection } = useSettings();

  // const handleClose = () => {
  //   setOpen(false);
  //   setActiveMenuChild((old) => (old !== null ? null : old));
  // };

  const handelNavigate = (item: SidebarItems, isParent: boolean, path: string) => {
    // if not parent then find parent and set
    let parentItem: SidebarItems | null = item || null;
    if (!isParent) {
      parentItem = menus.find((menu) => menu.children?.find((m) => m.path === path)) || null;
      // console.log('parentItem: ', parentItem);
    }

    if (parentItem) {
      setCurrentActiveNav(parentItem);
    }
    // console.log('path: ', path);
    navigate(path);
  };

  // const handelSettingNavigate = (item: any) => {
  //   const obj = {
  //     ...currentMenu,
  //     isPrivilegeSetting: true
  //   };
  //   obj.data = {
  //     ...obj.data,
  //     childMenu: item
  //   };
  //   // todo
  //   // dispatch(applicationCurrentNav(obj));
  //   navigate(item.path);
  // };

  const StyledPopover = styled(Popover)(({ theme }) => ({
    // pointerEvents: 'none',
    '& .MuiPopover-paper': {
      width: 180,
      // pointerEvents: 'auto',
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      boxShadow: theme.customShadows.dropdown,
      borderRadius: Number(theme.shape.borderRadius) * 1.5,
      ...bgBlur({ color: theme.palette.background.default })
    }
  }));
  //#endregion

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);

  const handlePopoverOpen = (event: any, index: any) => {
    setAnchorEl(event.currentTarget);

    setPopoverIndex(index);
  };

  const handlePopoverClose = (e?: any) => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const openPop = Boolean(anchorEl);
  return (
    <>
      <Drawer
        variant="persistent"
        sx={{
          width: DRAWER_NAV_WIDTH,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: DRAWER_NAV_WIDTH, boxSizing: 'border-box' },
          '&.MuiDrawer-root .MuiDrawer-paper': {
            marginTop: '6rem',
            height: 'calc(100% - 4rem)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }
        }}
        // className="mostly-customized-scrollbar"
        // className="simplebar-scrollbar simplebar-visible simplebar-track simplebar-horizontal simplebar-mask MADARCHOD"
        open={true}
      >
        <Box className="mostly-customized-scrollbar-box-child MOSTLY-CUSTOMISED-SCROLLBAR">
          <div style={{ paddingBottom: '2rem' }} className="CUSTOM_LISTITEM">
            {menus.map((nav, index) => {
              const active =
                nav.path === currentActiveNav?.path && nav.title === currentActiveNav?.title;

              return (
                <div key={index}>
                  <List>
                    <ListItem
                      disablePadding
                      className="app-nav-list"
                      sx={{
                        background: active ? PRIMARY_MAIN : ''
                      }}
                      onMouseEnter={(event) => handlePopoverOpen(event, index)}
                      // onMouseLeave={(event) => handlePopoverClose(event)}
                      onClick={(event) => {
                        handelNavigate(nav, true, nav.path);
                      }}
                    >
                      <ListItemButton
                        sx={{
                          padding: '6px 10px'
                        }}
                      >
                        <ListItemIcon sx={{ marginRight: '0px' }}>{nav.icon}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: '12px' }}>{t(nav.title)}</Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                  {nav?.children?.length ? (
                    <StyledPopover
                      open={openPop && popoverIndex === index}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: themeDirection === 'rtl' ? 'left' : 'right'
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: themeDirection === 'rtl' ? 'right' : 'left'
                      }}
                      elevation={3} // optional elevation
                      PaperProps={{
                        // onMouseEnter: (e) => {
                        //   handlePopoverOpen(e, index);
                        // },
                        onMouseLeave: (e) => {
                          handlePopoverClose(e);
                        }
                      }}
                    >
                      {nav.children?.map((content, index) => {
                        const childActive = content.path === location?.pathname;
                        // &&  nav.title === currentActiveNav?.title;

                        return (
                          <React.Fragment key={index}>
                            <ListItem
                              disablePadding
                              // className="app-nav-list"
                              sx={{
                                background: childActive ? PRIMARY_MAIN : ''
                                // my: 2,
                                // mx: 2
                              }}
                              onClick={() => {
                                handelNavigate(content, false, content.path);
                              }}
                              onMouseEnter={() => {
                                setAnchorEl(anchorEl); // Prevents closing the popover when mouse enters it
                              }}
                              // onMouseLeave={(e) => {
                              //   handlePopoverClose(e);
                              // }}
                            >
                              <ListItemButton
                                sx={{
                                  padding: '6px 10px'
                                }}
                              >
                                <ListItemIcon>{content.icon}</ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography sx={{ fontSize: '12px' }}>
                                      {t(content.title)}
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          </React.Fragment>
                        );
                      })}
                    </StyledPopover>
                  ) : null}
                </div>
              );
            })}
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
