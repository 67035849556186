import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// import CandidateDashboardScreen from '../pages/CandidateDashboardScreen';
import App from 'App';
import SchoolRoundAuthGuard from 'guards/SchoolRoundAuthGuard';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <AdminLoginScreen /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/change-password', element: <ChangePassword /> }
      ]
    },
    {
      path: '/school/',
      element: <LogoOnlyLayout />,
      children: [
        { path: ':schoolName', element: <App /> },
        { path: ':schoolName/login', element: <CandidateLogin /> },
        { path: ':schoolName/verification', element: <VerificationScreen /> },
        {
          path: ':schoolName/round/exam/:id',
          element: (
            <SchoolRoundAuthGuard>
              <RoundStudentExam />
            </SchoolRoundAuthGuard>
          )
        }
      ]
    },
    {
      path: 'applicant',
      element: (
        <SchoolRoundAuthGuard>
          <DashboardLayout />
        </SchoolRoundAuthGuard>
      ),
      children: [
        { element: <Navigate to={'/applicant/dashboard'} replace />, index: true },
        { path: 'admission-form', element: <AdmissionForm /> },
        { path: 'dashboard', element: <CandidateDashboard /> },
        { path: 'application-view', element: <CandidateApplicationView /> },
        { path: 'candidate-chat', element: <CandidateChat /> },
        { path: 'setup', element: <Setup /> }
      ]
    },
    {
      path: 'admin',
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to={'/admin/dashboard'} replace />, index: true },

        { path: 'login', element: <AdminLoginScreen /> }
      ]
    },
    {
      path: 'admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={'/admin/dashboard'} replace />, index: true },

        { path: 'admin-form', element: <AdminForm /> },
        { path: 'admission-form', element: <AdmissionFormForAdmin /> },
        // { path: 'admission-form', element: <div>Jadu </div> },
        { path: 'add-custom-component', element: <CustomComponent /> },
        { path: 'edit-field', element: <EditField /> },
        { path: 'application-list', element: <ApplicationList /> },
        { path: 'application-view', element: <ApplicationView /> },
        { path: 'school-information', element: <SchoolInformation /> },
        { path: 'add-sub-admin', element: <AddSubAdminScreen /> },
        { path: 'sub-admin-list', element: <SubAdminList /> },
        { path: 'favourites', element: <Favourites /> },
        {
          path: 'chat',
          children: [{ element: <NewChat /> }, { path: ':conversationKey', element: <NewChat /> }]
        },
        { path: 'dashboard', element: <AdminDashboard /> },
        { path: 'school-setup', element: <SchoolSetup /> },
        { path: 'fee-structure', element: <FeeStructure /> },
        { path: 'edit-sub-admin/:id', element: <EditSubAdminScreen /> },
        { path: 'notification-template', element: <NotificationTemplateScreen /> },
        {
          path: 'round',
          children: [
            { element: <Navigate to="/admin/round" replace />, index: true },
            { path: 'list', element: <RoundList /> },
            { path: 'create', element: <CreateRound /> },
            { path: 'edit/:id', element: <EditRound /> },
            { path: 'view/:id', element: <ViewRound /> },
            { path: 'exam/list', element: <RoundExamList /> },
            { path: 'applicant/list', element: <CandidateRoundEvaluationList /> },
            {
              path: 'evaluation',
              children: [
                { path: 'list', element: <RoundEvaluationList /> },
                { path: ':roundId/:applicantResultId', element: <RoundEvaluationPage /> }
              ]
            }

            // { path: 'exam/:id', element: <RoundStudentExam /> }
          ]
        },
        {
          path: 'academic-year-grade',
          children: [
            { element: <Navigate to="/admin/academic-year-grade" replace />, index: true },
            { path: 'list', element: <CreateAcademicYearGradeMapping /> }
            // { path: 'create', element: <CreateAcademicYearGradeMapping /> },
            // { path: 'edit/:id', element: <EditAcademicYearGradeMapping /> },
            // { path: 'view/:id', element: <ViewAcademicYearGradeMapping /> }
          ]
        },
        {
          path: 'academic-year-grade-round',
          children: [
            { element: <Navigate to="/admin/academic-year-grade-round" replace />, index: true },
            { path: 'list', element: <ListAcademicYearGradeRoundMapping /> },
            { path: 'create', element: <CreateAcademicYearGradeRoundMapping /> },
            // { path: 'edit/:id', element: <EditAcademicYearGradeRoundMapping /> },
            { path: 'view/:id', element: <ViewAcademicYearGradeRoundMapping /> }
          ]
        },

        {
          path: 'transaction',
          children: [
            { element: <Navigate to="/admin/transaction" replace />, index: true },
            { path: 'list', element: <TransactionList /> }
          ]
        },
        {
          path: 'template-editor',
          children: [
            { element: <Navigate to="/admin/template-editor" replace />, index: true },
            { path: 'list', element: <TemplateList /> },
            { path: 'create', element: <CreateTemplate /> },
            { path: 'edit/:id', element: <EditTemplate /> }
          ]
        },
        {
          path: 'invoice-template',
          children: [
            // { element: <Navigate to="/admin/invoice-template" replace />, index: true },
            // { element: <InvoiceTemplatePage />, index: true }
            { element: <TemplateEditorTab />, index: true }
          ]
        }
      ]
    },
    {
      path: 'super-admin',
      element: <LogoOnlyLayout />,
      children: [{ path: 'login', element: <AdminLoginScreen /> }]
    },
    {
      path: 'super-admin',
      element: <DashboardLayout />,
      children: [
        { path: 'add-school', element: <AddSchoolScreen /> },
        { path: 'edit-school-information/:school_id', element: <EditSchoolInformation /> },
        { path: 'school-list', element: <SchoolList /> },
        { path: 'admin-list', element: <AdminList /> },
        { path: 'dashboard', element: <SuperAdminDashboard /> },
        { path: 'edit-admin/:id', element: <EditAdminScreen /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const AdmissionForm = Loadable(lazy(() => import('../pages/AdmissionForm')));
const AdmissionFormForAdmin = Loadable(lazy(() => import('../pages/AdmissionFormNew')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const VerificationScreen = Loadable(lazy(() => import('../pages/VerificationScreen')));
const AdminForm = Loadable(lazy(() => import('../pages/AdminForm')));
const CustomComponent = Loadable(lazy(() => import('../components/My-Components/CustomComponent')));
const EditField = Loadable(lazy(() => import('../components/My-Components/EditField')));
const AdminLoginScreen = Loadable(lazy(() => import('../pages/AdminLoginScreen')));
const ApplicationList = Loadable(lazy(() => import('../pages/ApplicationList')));
const SchoolInformation = Loadable(lazy(() => import('../pages/SchoolInformation')));
const CandidateLogin = Loadable(lazy(() => import('../pages/CandidateLoginScreen')));
const ApplicationView = Loadable(lazy(() => import('../pages/ApplicationViewScreen')));
const CandidateDashboard = Loadable(lazy(() => import('../pages/CandidateDashboardScreen')));
const CandidateApplicationView = Loadable(lazy(() => import('../pages/CandidateApplicationView')));
const AddSchoolScreen = Loadable(lazy(() => import('../pages/AddSchoolScreen')));
const EditSchoolInformation = Loadable(lazy(() => import('../pages/EditSchoolInformation')));
const SchoolList = Loadable(lazy(() => import('../pages/SchoolList')));
const AddSubAdminScreen = Loadable(lazy(() => import('../pages/AddSubAdminScreen')));
const SubAdminList = Loadable(lazy(() => import('../pages/SubAdminList')));
const ForgotPassword = Loadable(lazy(() => import('../pages/ForgotPasswordScreen')));
const Favourites = Loadable(lazy(() => import('../pages/FavouritesApplicantList')));
const AdminList = Loadable(lazy(() => import('../pages/AdminListScreen')));
const NewChat = Loadable(lazy(() => import('../pages/NewChat')));
// const CandidateChatScreen = Loadable(lazy(() => import('../pages/CandidateChatScreen')));
const CandidateChat = Loadable(lazy(() => import('../pages/CandidateChat')));
const SuperAdminDashboard = Loadable(lazy(() => import('../pages/SuperAdminDashboardScreen')));
const AdminDashboard = Loadable(lazy(() => import('../pages/AdminDashboardScreen')));
const ChangePassword = Loadable(lazy(() => import('../pages/ChangePasswordScreen')));
const SchoolSetup = Loadable(lazy(() => import('../pages/SchoolSetup')));
const FeeStructure = Loadable(lazy(() => import('../pages/FeeStructure')));
const Setup = Loadable(lazy(() => import('../pages/SetupScreen')));
const EditAdminScreen = Loadable(lazy(() => import('../pages/EditAdminScreen')));
const EditSubAdminScreen = Loadable(lazy(() => import('../pages/EditSubAdminScreen')));
const NotificationTemplateScreen = Loadable(lazy(() => import('../pages/NotificationTemplate')));
const RoundForm1 = Loadable(lazy(() => import('../pages/RoundForm1')));
const RoundExamList = Loadable(lazy(() => import('../pages/RoundExamList')));
const RoundList = Loadable(lazy(() => import('../pages/RoundList')));
const RoundStudentExam = Loadable(lazy(() => import('../pages/RoundStudentExam')));
const CreateRound = Loadable(lazy(() => import('../pages/RoundPage')));
const EditRound = Loadable(lazy(() => import('../pages/RoundPage')));
const ViewRound = Loadable(lazy(() => import('../pages/RoundPage')));
const CandidateRoundEvaluationList = Loadable(
  lazy(() => import('../pages/CandidateRoundEvaluationList'))
);
const RoundEvaluationList = Loadable(lazy(() => import('../pages/RoundEvaluationList')));
const RoundEvaluationPage = Loadable(lazy(() => import('../pages/RoundEvaluationPage')));

const CreateAcademicYearGradeMapping = Loadable(
  lazy(() => import('../pages/AcademicYearGradeMappingPage'))
);
const EditAcademicYearGradeMapping = Loadable(
  lazy(() => import('../pages/AcademicYearGradeMappingPage'))
);
const ViewAcademicYearGradeMapping = Loadable(
  lazy(() => import('../pages/AcademicYearGradeMappingPage'))
);
const ListAcademicYearGradeRoundMapping = Loadable(
  lazy(() => import('../pages/AcademicYearGradeRoundMappingList'))
);
const CreateAcademicYearGradeRoundMapping = Loadable(
  lazy(() => import('../pages/AcademicYearGradeRoundMappingPage'))
);
// const EditAcademicYearGradeRoundMapping = Loadable(
//   lazy(() => import('../pages/AcademicYearGradeRoundMappingPage'))
// );
const ViewAcademicYearGradeRoundMapping = Loadable(
  lazy(() => import('../pages/AcademicYearGradeRoundMappingPage'))
);
const TransactionList = Loadable(lazy(() => import('../pages/transaction/TransactionList')));

const InvoiceTemplatePage = Loadable(
  lazy(() => import('../pages/invoice-editor/InvoiceTemplatePage'))
);
const TemplateList = Loadable(lazy(() => import('../pages/template-editor/List')));
const CreateTemplate = Loadable(lazy(() => import('../pages/template-editor/EditPage')));
const EditTemplate = Loadable(lazy(() => import('../pages/template-editor/EditPage')));

const TemplateEditorTab = Loadable(lazy(() => import('../pages/invoice-editor/TemplateEditorTab')));
