export const setUser = (user: any) => {
    return {
        type: 'SET_USER',
        payload: user,
    }
}

export const setSchool = (user: any) => {
    return {
        type: 'SET_SCHOOL',
        payload: user,
    }
}

export const setUnreadMessageCount = (user: any) => {
    return {
        type: 'SET_UNREAD_MESSAGE_COUNT',
        payload: user,
    }
}

export const setNotificationCount = (user: any) => {
    return {
        type: 'SET_UNREAD_NOTIFICATION_COUNT',
        payload: user,
    }
}
