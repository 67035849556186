import { useState, ReactNode } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
// import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
// import { PATH_AUTH } from 'routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

// http://localhost:3100/school/example/round/exam/86017104-19e7-4254-8cc5-8fce3d10811a

export default function ApplicantAuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const id = useParams<any>();
  const requestedLocation = localStorage.getItem('requestedLocation') || '';
  //   localStorage.removeItem('requestedLocation');


  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      localStorage.setItem('requestedLocation', pathname);
    }
    return <Navigate to={`/school/${id?.schoolName}/login`} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
