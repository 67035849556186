// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { StrictMode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/JWTContext';
import { createStore } from 'redux';
import allReducers from './reducers';
import { Provider } from 'react-redux';
import LoadingScreen from 'components/LoadingScreen';
// lightbox
import 'react-image-lightbox/style.css';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const persistConfig = {
  key: 'persist-store',
  storage
};
const persistedReducer = persistReducer(persistConfig, allReducers);
const store: any = createStore(
  persistedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);
// i18n
// import i18next from './locales/i18n';
// ----------------------------------------------------------------------

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <SnackbarProvider>
              <AuthProvider>
                <Provider store={store}>
                  <PersistGate persistor={persistor}>
                    <DndProvider backend={HTML5Backend}>
                      <Suspense fallback={<LoadingScreen />}>
                        <App />
                      </Suspense>
                    </DndProvider>
                  </PersistGate>
                </Provider>
              </AuthProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
