import { useRef, useState } from 'react';
// material
import { Box, MenuItem, ListItemText } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// Multi Language
import i18n from '../../locales/i18n';
import { SettingsContext } from 'contexts/SettingsContext';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  // const schoolLanguages = JSON.parse(
  //   localStorage.getItem('schoolLanguages') ||
  //     `
  //   [
  //     {
  //       "id": 1,
  //       "displayName": "English",
  //       "langCode": "en"
  //     },
  //     {
  //       "id": 2,
  //       "displayName": "French",
  //       "langCode": "fr"
  //     },
  //     {
  //       "id": 3,
  //       "displayName": "Spanish",
  //       "langCode": "es"
  //     },
  //     {
  //       "id": 4,
  //       "displayName": "Arabic",
  //       "langCode": "ar"
  //     }
  //   ]
  // `
  // );

  const schoolObj: any = useSelector((state: any) => state.school);

  const defaultLanguagesArray = [
    {
      id: 1,
      displayName: 'English',
      langCode: 'en'
    },
    {
      id: 2,
      displayName: 'French',
      langCode: 'fr'
    },
    {
      id: 3,
      displayName: 'Spanish',
      langCode: 'es'
    },
    {
      id: 4,
      displayName: 'Arabic',
      langCode: 'ar'
    }
  ];

  const schoolLanguages = schoolObj.languageMasters || defaultLanguagesArray;

  const changeLanguage = (value: any) => {
    if (value === 'ar') {
      i18n.changeLanguage(value);
    } else {
      i18n.changeLanguage(value);
    }
    setOpen(false);
  };

  return (
    <SettingsContext.Consumer>
      {({ onLanguageDirectionChange }) => (
        <>
          <MIconButton
            ref={anchorRef}
            onClick={() => setOpen(true)}
            sx={{
              padding: 0,
              width: 44,
              height: 44,
              ...(open && { bgcolor: 'action.selected' })
            }}
          >
            {/* <img src={LANGS[0].icon} alt={LANGS[0].label} /> */}
            <Icon icon="emojione:globe-with-meridians" />
          </MIconButton>

          <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
            <Box sx={{ py: 1 }}>
              {schoolLanguages.map((option: any) => (
                <MenuItem
                  key={option.id}
                  onClick={() => {
                    changeLanguage(option.langCode);
                    onLanguageDirectionChange(option.langCode);
                    // if(localStorage.getItem('schoolDefaultLanguage') === 'ar'){
                    //   onLanguageDirectionChange('ar')
                    // }
                  }}
                  sx={{ py: 1, px: 2.5 }}
                >
                  {/* <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} />
              </ListItemIcon> */}
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    {option.displayName}
                  </ListItemText>
                </MenuItem>
              ))}
            </Box>
          </MenuPopover>
        </>
      )}
    </SettingsContext.Consumer>
  );
}
