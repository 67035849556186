const notificationCountReducer = (state: any = {}, action: any) => {
    switch (action.type) {
      case 'SET_UNREAD_NOTIFICATION_COUNT':
        return action.payload;
  
      case 'CLEAR_UNREAD_NOTIFICATION_COUNT':
        return state;
  
      default:
        return state;
    }
  };
  export default notificationCountReducer;
  