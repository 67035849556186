import userReducer from './userReducer';
import { combineReducers } from 'redux';
import schoolReducer from './schoolReducer';
import unreadMessageCountReducer from './unreadMessageCountReducer';
import notificationCountReducer from './notificationCount';

const allReducers = combineReducers({
  user: userReducer,
  school: schoolReducer,
  unreadMessageCount: unreadMessageCountReducer,
  notificationCount: notificationCountReducer
});

export default allReducers;
