import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { candidateLogin } from 'Services/candidate.service';
import { verifyOtp } from '../Services/otp.service';
import { adminSignIn } from 'Services/admin.service';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: null
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          const user: any = localStorage.getItem('role');
          const schoolName = localStorage.getItem('schoolName');
          const schoolId: any = localStorage.getItem('schoolId');
          if (user !== null) {
            if (user !== 'candidate') {
              localStorage.clear();
              navigate('/admin/login');
            } else {
              localStorage.clear();
              localStorage.setItem('schoolId', schoolId);
              navigate(`${schoolName}`);
            }
          }
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  // const login = async (email: string, password: string) => {
  //   const response = await axios.post('/api/account/login', {
  //     email,
  //     password
  //   });
  //   const { accessToken, user } = response.data;

  //   setSession(accessToken);
  //   dispatch({
  //     type: Types.Login,
  //     payload: {
  //       user
  //     }
  //   });
  // };

  const login: any = async (username: string, password: string, schoolId: any) => {
    const response = await candidateLogin({ username, password }, schoolId);
    const { accessToken } = response.data.data;
    localStorage.setItem('role', response.data.data.user.role);
    const user = response.data.data.user.role;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
    return response;
  };

  const verify: any = async (data: any, id: any) => {
    const response = await verifyOtp(data, id);
    const { accessToken } = response.data.data;
    localStorage.setItem('role', response.data.data.user.role);
    const user: any = response.data.data.user.role;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
    return response;
  };

  const adminLogin: any = async (data: any) => {
    const response = await adminSignIn(data);
    const { accessToken } = response.data.data;
    localStorage.setItem('role', response.data.data.user.role);
    const user = response.data.data.user.role;
    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
    return response;
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => '';

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        verify,
        adminLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
