import axios from './baseService/axiosInstance.service';
const URL = process.env.REACT_APP_API_URL;

export const generateOtp = (data: any, id: any) => {
  return axios.post(`${URL}/otp-validation/generate-otp/${id}`, data);
};

export const forceGenerateOtp = (data: any, id: any) => {
  return axios.post(`${URL}/otp-validation/generate-otp/${id}/?forceOtp=forceOtp`, data);
};

export const verifyOtp = (data: any, id: any) => {
  return axios.post(`${URL}/otp-validation/verify-otp/${id}`, data);
};
