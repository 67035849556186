// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  let userObj: any = localStorage.getItem('schoolData');
  if (userObj) {
    userObj = JSON.parse(userObj);
  }
  const user: any = useSelector((state: any) => state.user);
  return (
    <>
      {userObj?.logo && user.role !== 'admin' ? (
        <Box sx={{ width: 'auto', height: 85, ...sx }} component="img" src={userObj?.logo}></Box>
      ) : (
        <Box sx={{ width: 'auto', height: 85, ...sx }}>
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1192.76 444.46"
          >
            <defs>
              <style>
                {
                  '.cls-1,.cls-6{fill:#f8c900;}.cls-3{fill:#1c9b12;}.cls-4,.cls-7{fill:#253db7;}.cls-5{fill:#e23a00;}.cls-6,.cls-7{fill-rule:evenodd;}'
                }
              </style>
            </defs>
            <title>White Logo OA SVG-03</title>
            <rect className="cls-1" x="114.58" y="61.37" width="81.36" height="13.38" rx="4.92" />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M9.1,103.2q0-8.6,9.89-12.89T42.68,86q5.73,0,8.07,3t5.73,13.67q9.1,30.19,16.53,58.7t11.06,42Q87.72,217,90.58,223.2a4,4,0,0,0,3.64,2.34,3.69,3.69,0,0,0,3.91-3.12q2.07-7.54,6.9-24.34t7.94-27.2q3.12-10.41,7.67-24.21t8.46-24.21c.53-1.39,1.26-3.68,2.22-6.9a73.18,73.18,0,0,1,2.47-7.29,27.37,27.37,0,0,1,3.38-5.59,12.54,12.54,0,0,1,6.51-4.43A34.31,34.31,0,0,1,153.83,97h1.83a2.06,2.06,0,0,0,.65-.13,2,2,0,0,1,.65-.13,33.76,33.76,0,0,1,11.84,1.82,14.55,14.55,0,0,1,7.55,6.51,60,60,0,0,1,3.77,7.29,96.38,96.38,0,0,1,2.87,9.37q3.38,11.72,12.23,45.29t15.36,52.85q.78,2.87,4.17,2.86a3.69,3.69,0,0,0,3.9-3.13q21.09-77,34.36-111.67c.52-1.21,1.21-3.08,2.08-5.59a51.29,51.29,0,0,1,1.83-4.82c.34-.69.91-1.86,1.69-3.51a8.23,8.23,0,0,1,1.95-3c.52-.34,1.3-.91,2.34-1.69a7.38,7.38,0,0,1,3.52-1.43c1.3-.17,2.82-.35,4.55-.52s3.82-.26,6.25-.26c.35,0,1.08,0,2.21.13s2,.13,2.48.13a4.17,4.17,0,0,0,1-.13,4.06,4.06,0,0,1,1-.13q10.67,0,14.84,2.86T303,98.51a45.35,45.35,0,0,1-1.3,9.89q-1.05,10.42-26.81,91.63T238.7,306.5a27.82,27.82,0,0,1-5.73,9,13.46,13.46,0,0,1-5.86,3.64,70.42,70.42,0,0,1-9.24,1.17h-.52a49.55,49.55,0,0,1-10-1.3,12.85,12.85,0,0,1-6.51-3.77,45.41,45.41,0,0,1-3.38-4,28.15,28.15,0,0,1-2.48-6.37,58,58,0,0,0-2.08-6.12q-6.24-18.22-11.84-36.83t-12-41q-6.37-22.4-10-34.36-.78-2.61-3.91-2.61a3.85,3.85,0,0,0-3.9,2.61Q143.42,207.32,132.1,246t-16.79,55c-1,3-1.87,5.17-2.47,6.64a29.65,29.65,0,0,1-3.52,5.73,15.37,15.37,0,0,1-7,5.2,29.86,29.86,0,0,1-10.67,1.69H89.54A37.73,37.73,0,0,1,82,319.12a13.5,13.5,0,0,1-5.86-3.25q-2.47-2.34-4-4A18.76,18.76,0,0,1,69,306.24c-1-2.61-1.78-4.47-2.21-5.6s-1-3.21-1.82-6.25-1.26-4.9-1.43-5.59q-1-3.65-13.8-47.38T25,157.21q-12-40.47-14.84-48.29A14.12,14.12,0,0,1,9.1,103.2Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M370.67,185.19q-8.34-11.17-16.79-23.68t-19.52-29.68q-11.07-17.18-15.75-24.47c-2.25-3.47-3.39-6.51-3.39-9.11Q315.22,86,340.74,86c3.46,0,6.46,0,9,.13a23.16,23.16,0,0,1,6.76,1.17L361,88.88a10,10,0,0,1,3.64,2.73c1.39,1.48,2.35,2.52,2.87,3.13a54.41,54.41,0,0,1,3.12,4.55q2.34,3.64,3.39,5.21,6.76,11.72,45.55,66.9a3.09,3.09,0,0,0,3,1.43,3.73,3.73,0,0,0,3-1.69q.78-1.3,19-32.54T473,93.31q4.42-6.25,8.07-7.68t12.5-1.44H504q19.52,0,19.52,10.42a14.4,14.4,0,0,1-1.17,5.46,17.1,17.1,0,0,1-2.47,4.43l-1,1.56q-.27.52-15.1,23.56T471.28,180q-17.56,27.33-23.56,36.44a17.71,17.71,0,0,0-3.12,9.37q0,50.25-.78,72.89-1.05,11.46-5.08,14.58t-14.19,3.12h-4.42q-14.06,0-18.61-2.47t-5.08-13.15q-1.81-34.62-1.82-73.93a18.31,18.31,0,0,0-2.34-9.11Q386.28,206.29,370.67,185.19Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M616.66,321.34q-26.55-1.05-45-7.68a70,70,0,0,1-31.5-23c-.17-.17-.78-.91-1.82-2.21L535.58,285a48,48,0,0,1-2.87-4A46.72,46.72,0,0,1,530,276.3a29.47,29.47,0,0,1-1.82-4.68,17.3,17.3,0,0,1-.78-4.95q0-10.67,16.92-13,9.11-1.83,11.71-1.83a8.64,8.64,0,0,1,6.38,2.22q2.21,2.2,7.81,8.2a89.19,89.19,0,0,0,14.71,12.23q14.83,10.15,30.19,10.15t26-10q10.67-10,10.67-21a16.24,16.24,0,0,0-1.82-7.29q-5.73-11.46-22.51-19.53a274.1,274.1,0,0,0-38-14.57q-21.23-6.51-28.5-10.68-25.26-14.31-25.25-45.29a83.83,83.83,0,0,1,2.34-16.92q6.51-29.92,38.79-42.95,14.56-5.73,33.58-5.73a118.5,118.5,0,0,1,31.23,4,75.58,75.58,0,0,1,27.73,14.44,53.68,53.68,0,0,1,17.83,26.3,14,14,0,0,1,.26,3.12q0,6.24-9.9,11.71Q669,155,663,155q-10.15,0-19.53-13.54-9.11-13-29.15-13-19.79,0-28.63,12.23a19.05,19.05,0,0,0-3.91,11.2q0,7,5.34,13.14a29.79,29.79,0,0,0,12.62,8.72Q644,190.92,665.6,200.55q15.61,6.51,22.91,20.7a68.29,68.29,0,0,1,7.29,31.62,87.84,87.84,0,0,1-2.61,20.31q-4.43,20.29-27.46,34.23t-48,13.93Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M897,310.92H886.59c-3.47,0-6.21-.09-8.2-.26a17.11,17.11,0,0,1-5.6-1.43,11,11,0,0,1-3.64-2.34,17.62,17.62,0,0,1-2.47-4.69,45.56,45.56,0,0,1-2.22-6.9q-.78-3.37-2.73-10.15t-4.29-13.27q-1.56-5-14.58-7.68a148.3,148.3,0,0,0-30.2-2.74A135,135,0,0,0,782,264.59q-13.28,3.12-15.62,9.11a110.55,110.55,0,0,0-4.17,12.23q-2.08,7.29-3.51,11.07a39.05,39.05,0,0,1-4,7.68,14.36,14.36,0,0,1-7,5.59,31.71,31.71,0,0,1-11.2,1.69,89.74,89.74,0,0,1-10.93-.78q-6.76-1-10.67-4.68a11.93,11.93,0,0,1-3.91-9.11,16.35,16.35,0,0,1,1.3-6.77q6.51-16.39,23.69-63T763.73,156q4.93-12,10-25.64t7.42-20.17A116.8,116.8,0,0,1,787,96.82q3.53-6.9,7.16-9.63a31.86,31.86,0,0,1,9.24-4.56,52.14,52.14,0,0,1,14.19-2.08h4.69q15.35,0,21.21,9t20.43,52.19q23.17,68.73,45.82,127.81c.34.87,1.3,3.3,2.86,7.29s2.61,6.73,3.13,8.2a62.53,62.53,0,0,1,1.69,6.12,30.82,30.82,0,0,1,.91,6.77Q918.35,310.93,897,310.92ZM783.77,219.3v1.3A4.94,4.94,0,0,0,785.2,224a5.53,5.53,0,0,0,3.52,1.82l8.85.78q7,.78,26.81.78c2.25,0,5.24-.08,9-.26s6.38-.26,7.94-.26a3.84,3.84,0,0,0,3.25-1.56,3.67,3.67,0,0,0,.65-3.38Q834,188.06,817.87,144.85a3,3,0,0,0-2.73-2,2.56,2.56,0,0,0-2.74,1.7q-5.46,11.44-15,35.66T783.77,219.3Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M1064.36,243a6.45,6.45,0,0,0-5.47-2.86,6.92,6.92,0,0,0-5.72,2.6q-34.62,40.61-56,67.16-6.51,9.38-25.77,9.37a61.57,61.57,0,0,1-10.93-.78q-13-1.56-13-12.75A10.1,10.1,0,0,1,949,300q7.29-10.15,73.15-97.35a11,11,0,0,0,2.34-6,11.24,11.24,0,0,0-2.34-6.25l-28.76-37.74q-24.6-32.28-34.23-44.52c-2.09-3-3.13-5.46-3.13-7.55a10.37,10.37,0,0,1,3.65-7.8,19.76,19.76,0,0,1,8.85-4.69Q982.88,85,988.09,85c1.39,0,2.64,0,3.78.13a9.74,9.74,0,0,1,3.38.91q1.69.78,2.73,1.17a9.8,9.8,0,0,1,2.74,2c1.12,1,1.95,1.78,2.47,2.21a25.26,25.26,0,0,1,2.6,2.87c1.21,1.47,2,2.51,2.47,3.12l2.87,4c1.47,2.09,2.56,3.56,3.25,4.43q11.46,16.14,37.49,48.94a5.25,5.25,0,0,0,4.68,2.34,5.69,5.69,0,0,0,4.69-2.34q34.35-44,46.33-58.83A78.35,78.35,0,0,1,1113,89.4a18.67,18.67,0,0,1,5.73-3.51,24.17,24.17,0,0,1,9.63-1.7q2.6,0,11.45,1,16.65,3.38,16.66,14.05a15.21,15.21,0,0,1-3.38,9.89q-5,6.51-62.48,79.66a9.44,9.44,0,0,0-2.08,6.51,10.07,10.07,0,0,0,2.6,6.5q54.67,67.69,73.67,92.67,5.73,6.51,5.73,12.76,0,12.75-22.91,12.75h-3.38q-9.37,0-15-2.08t-11.07-9.37Q1093.25,280.73,1064.36,243Z"
            />
            <path
              className="cls-1"
              d="M999.3,312.22A33.3,33.3,0,1,1,966,278.92,33.29,33.29,0,0,1,999.3,312.22Z"
            />
            <path
              className="cls-3"
              d="M1183.66,310.44a33.3,33.3,0,1,1-33.3-33.29A33.3,33.3,0,0,1,1183.66,310.44Z"
            />
            <path
              className="cls-4"
              d="M1004.41,86.57a33.3,33.3,0,1,1-33.3-33.29A33.3,33.3,0,0,1,1004.41,86.57Z"
            />
            <path
              className="cls-5"
              d="M1175.77,86.58a33.3,33.3,0,1,1-33.3-33.3A33.3,33.3,0,0,1,1175.77,86.58Z"
            />
            <path
              className="cls-6"
              d="M224.33,91.6A106.77,106.77,0,0,1,221,81a48.43,48.43,0,0,1-1-7.47v-4c0-2.13.34-5.52.33-6.15s.13-2.72.13-4.66-.82-3.53-2-3.53H214c-1.13,0-2,1.59-2,3.53s0,4,0,4.66.05,4,.05,6.15v4a49.71,49.71,0,0,1-.75,7.7,74.94,74.94,0,0,1-3.06,10.35c-.23.6-.85,3.28,2.45,3.28h11.15C225.15,94.88,224.56,92.2,224.33,91.6Z"
            />
            <rect className="cls-1" x="215.01" y="37.76" width="1.97" height="20.68" />
            <rect
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              x="216.98"
              y="79.12"
              width="0.98"
              height="15.76"
            />
            <rect
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              x="214.03"
              y="79.12"
              width="0.98"
              height="15.76"
            />
            <path
              className="cls-7"
              d="M89.92,38.88c7.93-2.81,15.75-5.45,23.48-8.34,11.74-4.41,23.35-9.21,34.07-15.81,2.76-1.72,5.34-3.76,7.89-5.56,3.4,2.29,6.74,4.79,10.33,6.89C178,23.21,191.19,28.3,204.5,33.12c5.43,2,10.94,3.77,16.56,5.7l-1.19,1.3c-7.78,8.14-15.59,16.29-23.35,24.45a2.67,2.67,0,0,1-2.22.83c-4.35,0-8.68.35-13,.39-13.84.17-27.71.4-41.54.33-7.13,0-23-.63-23.42-.67-1.28-.11-2.18-1.14-3.16-2.17-7.42-7.83-14.9-15.6-22.35-23.4C90.5,39.58,90.25,39.25,89.92,38.88Z"
            />
            <path
              className="cls-7"
              d="M155.35,71.34c12.1-.44,24.18-.78,36.24-1.4a6.29,6.29,0,0,1,7,6.25,76.5,76.5,0,0,1,0,8.7c-.37,4.34-2.47,7.57-6.71,9.19-24.54,9.36-49.05,9.19-73.5-.33-3.93-1.52-5.65-4.82-5.93-8.82-.19-2.9-.09-5.8,0-8.71.08-4,2.81-6.4,6.78-6.23Q137.24,70.68,155.35,71.34Z"
            />
            <path
              className="cls-6"
              d="M161.85,10.11a6.75,6.75,0,1,1-6.75-6.75A6.75,6.75,0,0,1,161.85,10.11Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M166.91,392.53a31.27,31.27,0,0,1,2.17-11.64,24.58,24.58,0,0,1,6.54-9.38A25.29,25.29,0,0,1,193.19,365,27.15,27.15,0,0,1,204,367a24.18,24.18,0,0,1,8.35,5.84,25.88,25.88,0,0,1,5.34,8.79,31.12,31.12,0,0,1,1.86,10.86,34.33,34.33,0,0,1-2.16,12.21,24.44,24.44,0,0,1-6.62,9.75,23.57,23.57,0,0,1-8,4.8,28.73,28.73,0,0,1-20-.39,24.08,24.08,0,0,1-8.62-5.85Q166.91,405.36,166.91,392.53Zm13,.08a24.59,24.59,0,0,0,1.42,8.33,14.61,14.61,0,0,0,4.41,6.5,11.31,11.31,0,0,0,7.5,2.51,11.45,11.45,0,0,0,7.55-2.46,14.09,14.09,0,0,0,4.34-6.4,25.69,25.69,0,0,0,1.36-8.48,23.23,23.23,0,0,0-1.44-8.2,13.69,13.69,0,0,0-4.47-6.18,12,12,0,0,0-7.49-2.33,11.61,11.61,0,0,0-5,1.15,12.75,12.75,0,0,0-4.16,3.09Q179.93,384.67,179.93,392.61Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M248.85,387.25v26.28a7.35,7.35,0,0,1-1.6,4.84,6.11,6.11,0,0,1-9,0,7.19,7.19,0,0,1-1.66-4.83V371.77a6.38,6.38,0,0,1,1.68-4.51,5.58,5.58,0,0,1,4.31-1.81,6.23,6.23,0,0,1,5.1,2.71l23.56,29.64V372.23a7.26,7.26,0,0,1,1.64-4.82,5.56,5.56,0,0,1,4.51-2,5.46,5.46,0,0,1,4.44,1.95,7.27,7.27,0,0,1,1.64,4.83V413.3a7.5,7.5,0,0,1-1.61,5.19A5.87,5.87,0,0,1,273,418Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M315.22,408.58h12.62A6.53,6.53,0,0,1,332.2,410a5.06,5.06,0,0,1,1.7,4.06,5,5,0,0,1-1.73,4,6.47,6.47,0,0,1-4.33,1.47H310.66a7.64,7.64,0,0,1-5.63-2,7.56,7.56,0,0,1-2-5.64V372.23a7.25,7.25,0,0,1,1.63-4.82,5.57,5.57,0,0,1,4.51-2,5.45,5.45,0,0,1,4.46,2,7.3,7.3,0,0,1,1.62,4.82Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M348.05,413.53v-41.3a7.26,7.26,0,0,1,1.64-4.82,5.53,5.53,0,0,1,4.5-2,5.47,5.47,0,0,1,4.47,2,7.3,7.3,0,0,1,1.62,4.82v41.3a7.36,7.36,0,0,1-1.61,4.84,5.49,5.49,0,0,1-4.48,1.95,5.55,5.55,0,0,1-4.48-2A7.19,7.19,0,0,1,348.05,413.53Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M391.9,387.25v26.28a7.36,7.36,0,0,1-1.61,4.84,6.11,6.11,0,0,1-9,0,7.19,7.19,0,0,1-1.66-4.83V371.77a6.38,6.38,0,0,1,1.68-4.51,5.58,5.58,0,0,1,4.31-1.81,6.23,6.23,0,0,1,5.1,2.71l23.56,29.64V372.23a7.26,7.26,0,0,1,1.64-4.82,5.56,5.56,0,0,1,4.51-2,5.5,5.5,0,0,1,4.45,1.95,7.31,7.31,0,0,1,1.63,4.83V413.3a7.55,7.55,0,0,1-1.6,5.19A5.88,5.88,0,0,1,416,418Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M458.26,377.19v10.14h11.41a6.5,6.5,0,0,1,4.33,1.46,5,5,0,0,1,1.75,4,4.86,4.86,0,0,1-1.77,4,6.48,6.48,0,0,1-4.31,1.48H458.26v10.31h12.62a6.53,6.53,0,0,1,4.36,1.44,5.06,5.06,0,0,1,1.7,4.06,4.93,4.93,0,0,1-1.73,4,6.47,6.47,0,0,1-4.33,1.47H453.47a6.78,6.78,0,0,1-7.43-7.43V373.68a6.83,6.83,0,0,1,7.43-7.43h17.41a6.49,6.49,0,0,1,4.36,1.46,5.09,5.09,0,0,1,1.7,4.06,4.88,4.88,0,0,1-1.73,3.94,6.43,6.43,0,0,1-4.33,1.48Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M557.78,409.38H537.35L536.06,414q-1.68,6.31-5.44,6.32a8.45,8.45,0,0,1-4.58-1.26,5,5,0,0,1-2.36-3.61,12,12,0,0,1,.49-3.85l14.37-39.52a10.8,10.8,0,0,1,3.5-5,8.59,8.59,0,0,1,5.52-1.9,8.71,8.71,0,0,1,5.53,1.88,10.72,10.72,0,0,1,3.5,5L571,411.6a12,12,0,0,1,.49,3.85,4.72,4.72,0,0,1-2.29,3.6,8.56,8.56,0,0,1-4.67,1.27,4.09,4.09,0,0,1-3.1-1.52,8.76,8.76,0,0,1-1.93-3.51Zm-3.67-10.94-6.47-20.3-6.47,20.3Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M587.32,411.94V373.68a6.83,6.83,0,0,1,7.43-7.43h11.51q10.37,0,17,4.87a22,22,0,0,1,7.34,9.44,31.45,31.45,0,0,1,2.49,12.36,30.28,30.28,0,0,1-3,13.67,22.5,22.5,0,0,1-8.69,9.5A25.42,25.42,0,0,1,608,419.52l-13.41-.16a7.13,7.13,0,0,1-5.35-2A7.51,7.51,0,0,1,587.32,411.94Zm12.23-3.05h6.06q6.82,0,10.22-3.58,4.23-4.46,4.23-12.39,0-8.46-4.87-12.69-3.65-3.28-10.94-3.28h-4.7Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M664.41,388.05l-4.15,26.44a7,7,0,0,1-2.37,4.24,7.21,7.21,0,0,1-4.73,1.51,4.15,4.15,0,0,1-3.77-2.07,10.56,10.56,0,0,1-1.28-5.59l6.55-40.11a7.55,7.55,0,0,1,7.84-6.94,9.59,9.59,0,0,1,5,1.3,6.59,6.59,0,0,1,3,3.88l8.95,27.4,8.22-26.27a9.88,9.88,0,0,1,3-4.51,7.31,7.31,0,0,1,4.87-1.8,9,9,0,0,1,5.38,1.72,6.75,6.75,0,0,1,2.85,4.59l7.11,40.56q0,7.85-5,7.84-4.27,0-5.83-2.16a11.07,11.07,0,0,1-1.6-4.95l-4.15-25.08-7.51,25a28.63,28.63,0,0,1-1.42,4,5.67,5.67,0,0,1-2,2.34,6.44,6.44,0,0,1-3.68.92,6.22,6.22,0,0,1-4.37-1.46,9.91,9.91,0,0,1-2.49-4.37Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M727.17,413.53v-41.3a7.26,7.26,0,0,1,1.64-4.82,5.56,5.56,0,0,1,4.51-2,5.47,5.47,0,0,1,4.46,2,7.3,7.3,0,0,1,1.62,4.82v41.3a7.36,7.36,0,0,1-1.61,4.84,5.47,5.47,0,0,1-4.47,1.95,5.57,5.57,0,0,1-4.49-2A7.25,7.25,0,0,1,727.17,413.53Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M757.51,382a15.4,15.4,0,0,1,2.69-9,17.49,17.49,0,0,1,7.15-5.94,22.35,22.35,0,0,1,9.5-2.07,34.88,34.88,0,0,1,6.44.65,16.34,16.34,0,0,1,6,2.33,5.19,5.19,0,0,1,2.54,4.53,6.15,6.15,0,0,1-1.58,4.25,5.19,5.19,0,0,1-4,1.75,5.91,5.91,0,0,1-1.83-.25l-2.25-.8a16.37,16.37,0,0,0-5.51-1,7.87,7.87,0,0,0-4.18,1.15,3.58,3.58,0,0,0-1.89,3.23q0,3.28,5,4.57l3.28.87A34.31,34.31,0,0,1,791,391.86q4.51,3.56,4.52,10.33,0,8.26-4.54,13a16,16,0,0,1-6.8,4.31,26.77,26.77,0,0,1-8.55,1.37,32,32,0,0,1-14-2.81,8.77,8.77,0,0,1-3.3-2.65,6.27,6.27,0,0,1-1.24-3.83,5.41,5.41,0,0,1,1.58-3.8,4.89,4.89,0,0,1,3.68-1.7,7.83,7.83,0,0,1,3.12.57l4.23,1.58a16.29,16.29,0,0,0,6,1.21,7.81,7.81,0,0,0,4.66-1.47,4.74,4.74,0,0,0,2-4.05,4.35,4.35,0,0,0-2-3.9,14.4,14.4,0,0,0-3.2-1.55q-1.56-.51-5.34-1.65a42.84,42.84,0,0,1-7.86-3,12.16,12.16,0,0,1-4.75-4.42A13.89,13.89,0,0,1,757.51,382Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M812.13,382a15.47,15.47,0,0,1,2.69-9A17.52,17.52,0,0,1,822,367a22.31,22.31,0,0,1,9.5-2.07,34.88,34.88,0,0,1,6.44.65,16.41,16.41,0,0,1,6,2.33,5.18,5.18,0,0,1,2.53,4.53,6.1,6.1,0,0,1-1.58,4.25,5.15,5.15,0,0,1-4,1.75,6,6,0,0,1-1.84-.25l-2.24-.8a16.43,16.43,0,0,0-5.52-1,7.84,7.84,0,0,0-4.17,1.15,3.58,3.58,0,0,0-1.89,3.23q0,3.28,5,4.57l3.28.87a34.17,34.17,0,0,1,12.24,5.65q4.53,3.56,4.53,10.33,0,8.26-4.55,13a15.88,15.88,0,0,1-6.79,4.31,26.77,26.77,0,0,1-8.55,1.37,32,32,0,0,1-14-2.81,8.7,8.7,0,0,1-3.3-2.65,6.28,6.28,0,0,1-1.25-3.83,5.46,5.46,0,0,1,1.58-3.8,4.91,4.91,0,0,1,3.69-1.7,7.9,7.9,0,0,1,3.12.57l4.23,1.58a16.25,16.25,0,0,0,6,1.21A7.81,7.81,0,0,0,835,408a4.73,4.73,0,0,0,2-4.05,4.34,4.34,0,0,0-2-3.9,14.62,14.62,0,0,0-3.2-1.55q-1.56-.51-5.35-1.65a43,43,0,0,1-7.85-3,12.16,12.16,0,0,1-4.75-4.42A13.89,13.89,0,0,1,812.13,382Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M868.05,413.53v-41.3a7.26,7.26,0,0,1,1.64-4.82,5.53,5.53,0,0,1,4.5-2,5.47,5.47,0,0,1,4.47,2,7.3,7.3,0,0,1,1.62,4.82v41.3a7.36,7.36,0,0,1-1.61,4.84,6.11,6.11,0,0,1-9,0A7.19,7.19,0,0,1,868.05,413.53Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M897.27,392.53a31.27,31.27,0,0,1,2.17-11.64,24.49,24.49,0,0,1,6.55-9.38A25.25,25.25,0,0,1,923.55,365a27.15,27.15,0,0,1,10.8,2.08,24.09,24.09,0,0,1,8.35,5.84,26.06,26.06,0,0,1,5.35,8.79,31.35,31.35,0,0,1,1.85,10.86,34.33,34.33,0,0,1-2.16,12.21,24.44,24.44,0,0,1-6.62,9.75,23.47,23.47,0,0,1-8,4.8,28.73,28.73,0,0,1-20-.39,24.08,24.08,0,0,1-8.62-5.85Q897.27,405.36,897.27,392.53Zm13,.08a24.84,24.84,0,0,0,1.42,8.33,14.61,14.61,0,0,0,4.41,6.5,11.31,11.31,0,0,0,7.5,2.51,11.43,11.43,0,0,0,7.55-2.46,14.09,14.09,0,0,0,4.34-6.4,25.69,25.69,0,0,0,1.36-8.48,23.23,23.23,0,0,0-1.44-8.2,13.69,13.69,0,0,0-4.47-6.18,12,12,0,0,0-7.49-2.33,11.61,11.61,0,0,0-5,1.15,12.75,12.75,0,0,0-4.16,3.09Q910.3,384.67,910.29,392.61Z"
            />
            <path
              className="cls-2"
              fill={`${PRIMARY_MAIN}`}
              d="M979.22,387.25v26.28a7.36,7.36,0,0,1-1.61,4.84,5.49,5.49,0,0,1-4.48,1.95,5.55,5.55,0,0,1-4.48-2,7.19,7.19,0,0,1-1.66-4.83V371.77a6.38,6.38,0,0,1,1.68-4.51,5.6,5.6,0,0,1,4.31-1.81,6.25,6.25,0,0,1,5.11,2.71l23.55,29.64V372.23a7.26,7.26,0,0,1,1.64-4.82,5.57,5.57,0,0,1,4.51-2,5.5,5.5,0,0,1,4.45,1.95,7.31,7.31,0,0,1,1.63,4.83V413.3a7.55,7.55,0,0,1-1.6,5.19,5.88,5.88,0,0,1-8.95-.49Z"
            />
          </svg>
        </Box>
      )}
    </>
  );
}
