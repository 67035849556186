import axios from './baseService/axiosInstance.service';
const URL = process.env.REACT_APP_API_URL;

export const getLastMessage = async (schoolId: any, userId: any, participantRole: any) => {
  const data = await axios.get(
    `${URL}/message/last-messages/?schoolId=${schoolId}&userId=${userId}&participantRole=${participantRole}`
  );
  return data;
};

export const getCandidateUnreadMessageCount = async (
  schoolId: any,
  userId: any,
  participantRole: any
) => {
  const data = await axios.get(
    `${URL}/message/candidate-unread-messages/?schoolId=${schoolId}&userId=${userId}&participantRole=${participantRole}`
  );
  return data;
};

export const getAdminUnreadMessageCount = async (
  schoolId: any,
  userId: any,
  participantRole: any
) => {
  const data = await axios.get(
    `${URL}/message/admin-unread-messages/?schoolId=${schoolId}&userId=${userId}&participantRole=${participantRole}`
  );
  return data;
};

export const getAllMessage = async (schoolId: any, userId: any, participantRole: any) => {
  const data = await axios.get(
    `${URL}/message/all-messages?schoolId=${schoolId}&userId=${userId}&participantRole=${participantRole}`
  );
  return data;
};

export const getAllMessageForCandidate = async (schoolId: any, userId: any, candidateId: any) => {
  const data = await axios.get(
    `${URL}/message/all-messages-for-candidate?schoolId=${schoolId}&userId=${userId}&candidateId=${candidateId}`
  );
  return data;
};

export const sendMessage = async (value: any, schoolId: any) => {
  const data = await axios.post(`${URL}/message/send/?schoolId=${schoolId}`, value);
  return data;
};

export const sendBulkMessage = async (value: any, schoolId: any) => {
  const data = await axios.post(`${URL}/message/send-bulk/?schoolId=${schoolId}`, value);
  return data;
};

export const updateMessage = async (id: any, value: any, schoolId: any) => {
  const data = await axios.put(`${URL}/message/${id}/?schoolId=${schoolId}`, value);
  return data;
};

export const markMessageAsRead = async (value: any, schoolId: any) => {
  const data = await axios.post(`${URL}/message/mark-read/?schoolId=${schoolId}`, value);
  return data;
};

export const deleteMessage = async (value: any, schoolId: any) => {
  const data = await axios.delete(`${URL}/message/delete/?schoolId=${schoolId}`, value);
  return data;
};

export const deleteConversation = async (id: any, schoolId: any) => {
  const data = await axios.delete(`${URL}/conversation/delete/${id}/?schoolId=${schoolId}`);
  return data;
};

export const getCandidateUnreadNotificationCount = async (id: any) => {
  const data = await axios.get(`${URL}/notification/${id}`);
  return data;
};