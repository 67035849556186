import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// const baseUrl = process.env.REACT_APP_API_URL;
// const schoolId = localStorage.getItem('schoolId') || 1;
// const schoolDefaultLanguage = localStorage.getItem('schoolDefaultLanguage');

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  // .init({
  //   supportedLngs: ['ar', 'en', 'es', 'fr'],

  //   // default language set
  //   lng: `${schoolDefaultLanguage}` || 'en',

  //   fallbackLng: `${schoolDefaultLanguage}` || 'en',
  //   // fallbackLng: 'en',

  //   keySeparator: false,

  //   detection: {
  //     order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
  //     caches: ['cookie']
  //   },

  //   backend: {
  //     loadPath: `${baseUrl}/translation/{{lng}}?schoolId=${schoolId}`,
  //     // loadPath: `${baseUrl}/all-translation/{{lng}}`,
  //     allowMultiLoading: true
  //   }
  // });

// });

export default i18n;
